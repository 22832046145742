import { useRailsContext } from 'components/rails-context'
import { useMemo } from 'react'
import { InternalApiClient } from '../services/internal-api-client'

export const useApiClient = () => {
  const { request, csrfToken } = useRailsContext()

  const service = useMemo(
    () => new InternalApiClient(request.baseUrl, csrfToken),
    [request, csrfToken]
  )

  return service
}
